// This file is taken from webgl example app and should eventually exist in one place. i.e. eegeo-web-tool-template.

export const createElementWithClassName = (elt: string, className: string): HTMLElement => {
  const divElement = document.createElement(elt);
  divElement.className = className;
  return divElement;
};

export const createButtonWithClassName = (className: string): HTMLButtonElement => {
  const buttonElement = createElementWithClassName("button", className);
  return buttonElement as HTMLButtonElement;
};

export const createDivWithClassName = (className: string): HTMLDivElement => {
  return createElementWithClassName("div", className) as HTMLDivElement;
};

export const createInputWithClassName = (className: string): HTMLInputElement => {
  return createElementWithClassName("input", className) as HTMLInputElement;
};

export const addButtonWithClassName = (className: string, parent: HTMLElement, onClickCallback: () => void, text: string): HTMLButtonElement => {
  const buttonElement = createButtonWithClassName(className);
  buttonElement.onclick = onClickCallback;
  if (text) {
    buttonElement.innerHTML = text;
  }
  parent.appendChild(buttonElement);
  return buttonElement;
};

export const addDivWithClassName = (className: string, parent: HTMLElement, text?: string): HTMLDivElement => {
  const divElement = createDivWithClassName(className);
  if (text) {
    const divText = document.createTextNode(text);
    divElement.appendChild(divText);
  }
  parent.appendChild(divElement);
  return divElement;
};

export const addTextInputWithClassName = (className: string, parent: HTMLElement): HTMLInputElement => {
  const textInputElement = createInputWithClassName(className);
  textInputElement.type = "text";
  parent.appendChild(textInputElement);
  return textInputElement;
};

export const ensureProtocolInUrl = (url: string, https = false): string => {
  if (url.search("https://") === 0 || url.search("http://") === 0 || url.search("mailto:") === 0) {
    return url;
  }
  return https ? "https://" + url : "http://" + url;
};

export const formatUrl = (url: string, strToFind: string, toPrefix: string, https = false): string => {
  if (url.search(strToFind) === -1) {
    url = toPrefix + url;
  }
  return ensureProtocolInUrl(url, https);
};