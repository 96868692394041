import wrld from "wrld.js";
import { getPoiViewHeader } from "../../view/components/PoiViewHeader";
import { getPoiViewImage } from "../../view/components/PoiViewImage";
import { getPoiViewDetails } from "../../view/components/PoiViewDetails";
import { getPoiViewTags } from "../../view/components/PoiViewTags";
import { getPoiViewDescription } from "../../view/components/PoiViewDescription";

import { createDivWithClassName, addDivWithClassName } from "../../helpers/DomHelpers";
import { simulateMouseUpEventsInsideIframe } from "../../helpers/IframeEventSimulations";
import { Poi, PoiData } from "../../types/Poi";

export type PoiViewOptions = {
    customViewScrollingEnabled: boolean;
    customViewDefaultHeight: number;
    customViewDataDeliveryMethod: "b64_message" | "query_string";
}

const getPoiHtmlView = (poiData: PoiData, options: PoiViewOptions) => {
  const htmlView = createDivWithClassName("poi-view-html-container");
  if (poiData.user_data.custom_view_height !== undefined) {
    htmlView.style.height = poiData.user_data.custom_view_height + "px";
  }
  else if(options.customViewDefaultHeight) {
    htmlView.style.height = options.customViewDefaultHeight + "px";
  }
  const frameElement = document.createElement("iframe");
  let customViewURL = poiData.user_data.custom_view;

  const sourceStyle = window.getComputedStyle(document.documentElement);
  poiData.styleCustomProperties = {
    "--primary-text-color": sourceStyle.getPropertyValue("--widgets-main-theme-primary-text-color"),
    "--secondary-text-color": sourceStyle.getPropertyValue("--widgets-main-theme-secondary-text-color"),
    "--background-color": sourceStyle.getPropertyValue("--widgets-main-theme-background-color"),
    "--ui-element-color": sourceStyle.getPropertyValue("--widgets-main-theme-ui-element-color"),
    "--ui-element-alt-color": sourceStyle.getPropertyValue("--widgets-main-theme-ui-element-alt-color"),
    "--button-text-color": sourceStyle.getPropertyValue("--widgets-main-theme-button-other-color"),
    "--minor-ui-element-color": sourceStyle.getPropertyValue("--widgets-main-theme-minor-ui-element-color"),
    "--box-shadow-color": sourceStyle.getPropertyValue("--widgets-box-shadow-light-color"),
    "--traffic-light-go-color": sourceStyle.getPropertyValue("--widgets-traffic-light-go-color"),
    "--traffic-light-changing-color": sourceStyle.getPropertyValue("--widgets-traffic-light-changing-color"),
    "--traffic-light-stop-color": sourceStyle.getPropertyValue("--widgets-traffic-light-stop-color"),
  };

  if(options.customViewDataDeliveryMethod === "b64_message") {
    frameElement.onload = function() {
      const poiDataJson = encodeURIComponent(JSON.stringify(poiData));
      const poiDataPrefix = "poid";
      frameElement.contentWindow.postMessage(poiDataPrefix + btoa(poiDataJson), "*");
    };
  }

  if(options.customViewDataDeliveryMethod === "query_string") {
    const separator = (customViewURL.indexOf("?")===-1)?"?":"&";
    const poiDataJson = encodeURIComponent(JSON.stringify(poiData));
    customViewURL = customViewURL + separator + "poi=" + poiDataJson;
  }

  frameElement.src = customViewURL;
  frameElement.frameBorder = "0";
  frameElement.className = "poi-view-html";
  frameElement.scrolling= options.customViewScrollingEnabled ? "yes" : "no";

  // Fix for MPLY-11317 (Mouseup events are lost when the mouse is moved inside an iframe)
  simulateMouseUpEventsInsideIframe(frameElement);

  htmlView.appendChild(frameElement);
  return htmlView;
};

const shouldShowPoiViewDetails = (poiData: PoiData): boolean => {
  return !!(poiData.user_data.address
    || poiData.user_data.phone
    || poiData.user_data.web
    || poiData.user_data.email
    || poiData.user_data.facebook
    || poiData.user_data.twitter);
};

export const PoiViewContainer = (map: wrld.Map, poi: Poi, options: PoiViewOptions, hidePoiCallback?: () => void): HTMLDivElement => {
  if (poi.data.user_data === undefined) {
    poi.data.user_data = {};
  }
  const outerElement = createDivWithClassName("poi-view-popup");
  outerElement.id = "eegeo-poi-card-popup";
  const headerElement = addDivWithClassName("poi-view-content", outerElement);
  headerElement.appendChild(getPoiViewHeader(poi, hidePoiCallback));
  let bodyClassName = "poi-view-content";
  let bodyOverflowStyle = "hidden";
  if(!(options.customViewScrollingEnabled && poi.data.user_data.custom_view)) {
    bodyClassName += " scrollable";
    bodyOverflowStyle = "auto";
  }

  let bodyElement = addDivWithClassName(bodyClassName, outerElement);
  bodyElement.id = "poi-view-content-body";
  const mapContainerHeight = map.getContainer().clientHeight;
  bodyElement.style.maxHeight = (mapContainerHeight-150) + "px";
  bodyElement.style.overflowY = bodyOverflowStyle;

  if (poi.data.user_data.custom_view) {
    bodyElement.appendChild(getPoiHtmlView(poi.data, options));

  } else if (poi.data.user_data.image_url) {
    const columnElement = addDivWithClassName("poi-view-content-column", bodyElement);
    const imageElement = getPoiViewImage(poi.data.user_data.image_url, poi.source === "yelp");
    columnElement.appendChild(imageElement);

    bodyElement = columnElement;

    const customHeight = poi.data.user_data.custom_view_height;

    if (customHeight !== undefined) {
      bodyElement.style.height = customHeight + "px";
    }

    if (shouldShowPoiViewDetails(poi.data)) {
      bodyElement.appendChild(getPoiViewDetails(poi.data));
    }
    const tags = getPoiViewTags(poi.tags);
    if (tags) {
      bodyElement.appendChild(tags);
    }
    const desc = getPoiViewDescription(poi);
    if (desc) {
      bodyElement.appendChild(desc);
    }
  }


  return outerElement;
};

export const resizePoiViewContainerToFit = (map: wrld.Map, poiViewContainer: HTMLElement): void => {
  const bodyElement = poiViewContainer.querySelector("#poi-view-content-body") as HTMLElement;
  if (!bodyElement) {
    return;
  }
  const mapContainerHeight = map.getContainer().clientHeight;
  bodyElement.style.maxHeight = (mapContainerHeight-150) + "px";
};

