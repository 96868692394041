import { createDivWithClassName } from "../../helpers/DomHelpers";

export const getPoiViewTags = (tags: string[]): HTMLDivElement => {
  if (tags && tags.length > 0) {
    const tagsDiv = createDivWithClassName("poi-view-tags");
    const divText = document.createTextNode(tags.join(", "));
    tagsDiv.appendChild(divText);
    return tagsDiv;
  }
  return null;
};
