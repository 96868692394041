import tagService from "../../../../common/template/services/TagService";

import { addDivWithClassName } from "../../helpers/DomHelpers";
import { Poi, PoiData } from "../../types/Poi";

const getClassForRating = (rating: number): string => {
  const decimalRating = rating.toString();
  const classNameSuffix = decimalRating.replace(".5", "-half");
  return "yelp-review-icon yelp-stars-" + classNameSuffix;
};

const addYelpContent = (poiViewData: PoiData, detailElement: HTMLElement) => {
  const classForRating = getClassForRating(poiViewData.user_data.rating);
  addDivWithClassName(classForRating, detailElement);
  addDivWithClassName("yelp-review", detailElement, poiViewData.user_data.review_count + " reviews on ");
  addDivWithClassName("yelp-logo", detailElement);
};

export const PoiCardContainer = (poiView: Poi): HTMLDivElement => {
  const containerElement = document.createElement("div");
  containerElement.className = "poi-card-popup";

  const poiIconElement = addDivWithClassName("poi-icon", containerElement);
  const iconUrl = tagService.getIconUrlForKey(poiView.iconKey);
  poiIconElement.style.backgroundImage = "url(" + iconUrl + ")";

  const detailElement = addDivWithClassName("poi-detail", containerElement);
  addDivWithClassName("poi-title", detailElement, poiView.title);

  if (poiView.data.user_data && poiView.data.user_data.address) {
    addDivWithClassName("poi-address", detailElement, poiView.data.user_data.address);
  }

  if (poiView.source === "yelp") {
    addYelpContent(poiView.data, detailElement);
  }
  else {
    if (poiView.tags) {
      addDivWithClassName("poi-tag-icon", detailElement);
      addDivWithClassName("poi-tags", detailElement, poiView.tags.join(", "));
    }
  }

  return containerElement;
};
