/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable max-lines */
// TODO: Break up into more files where appropriate!
import Evented from "../../common/js/Evented";

import pick from "lodash/pick";
import type wrld from "wrld.js";

import SearchbarSubscriber from "./SearchbarSubscriber";
import { measurePopupOnMap } from "./helpers/MapHelpers";
import { PoiCardContainer } from "./view/containers/PoiCardContainer";
import { PoiViewContainer, PoiViewOptions, resizePoiViewContainerToFit } from "./view/containers/PoiViewContainer";
import { ensureValidPoiViewData } from "./helpers/EnsureValidPoiViewData";
import { createMarkerIcon } from "./helpers/CreateMarkerIcon";
import { Marker, MarkerOptions, PopupContent } from "./types/Marker";
import { Searchbar } from "./types/Searchbar";

// current wrld.js option names
const indoorMapIdKey = "indoorMapId";
const indoorMapFloorIdKey = "indoorMapFloorId";
const indoorMapFloorIndexKey = "indoorMapFloorIndex";
const elevationKey = "elevation";
const elevationModeKey = "elevationMode";

// the marker controller was doing visibility etc. using its own options that do not correspond to wrld.js marker options
const mcIsIndoorKey = "isIndoor";
const mcIndoorIdKey = "indoorId";
const mcFloorIndexKey = "floorIndex";

type WrldMarkerControllerOptions = {
    /** An instance of a WrldSearchbar from which to automatically generate markers. Each search result will generate a marker with an appropriate icon and title, which will be cleared when the search is cleared. */
    searchbar?: Searchbar;
    /** Whether or not POI Views will be created for the markers. */
    poiViewsEnabled?: boolean;
    /** The default height of custom views when customViewHeight is not defined. */

    ignoreTags?: string[];
    skipPoiCard?: boolean

} & PoiViewOptions;

const defaultOptions: WrldMarkerControllerOptions = {
  poiViewsEnabled: false,
  ignoreTags: [],
  skipPoiCard: false,
  customViewDataDeliveryMethod: "query_string",
  customViewDefaultHeight: undefined,
  customViewScrollingEnabled: false
};

type Animation = "appear" | "disappear";
type PopupEvent = L.PopupEvent & { popup: wrld.Popup };

class WrldMarkerController extends Evented {
    private _map: wrld.Map;
    private _options: WrldMarkerControllerOptions;
    private _markers: Record<string, Marker>;
    private _markersPendingDelete: Record<string, Marker>;
    private _markerIdToZIndexOffset: Record<string, number>;
    private _selectedMarkerId: null | string;
    private _hasAnchors: boolean;
    private _animationKeys: Animation[];
    private _searchbarSubscriber: SearchbarSubscriber | null;


    constructor(map: wrld.Map, options?: WrldMarkerControllerOptions) {
      super();
      this._map = map;
      this._options = Object.assign({}, defaultOptions, options);
      this._markers = {};
      this._markersPendingDelete = {};

      this._selectedMarkerId = null;
      this._hasAnchors = this._shouldHaveAnchors();
      this._animationKeys = ["appear", "disappear"];

      this._map.on("update", this._onUpdate.bind(this));
      this._map.on("zoom", this._updateAnchors.bind(this));
      this._map.on("tilt", this._updateAnchors.bind(this));

      this._searchbarSubscriber = null;

      this._addMarkerForSearchResult = this._addMarkerForSearchResult.bind(this);
      this._removeMarkerForSearchResult = this._removeMarkerForSearchResult.bind(this);
      this._updateMarkerZIndexOffset = this._updateMarkerZIndexOffset.bind(this);

      if ("searchbar" in this._options) {
        this._searchbarSubscriber = new SearchbarSubscriber(this._options["searchbar"], this._addMarkerForSearchResult, this._removeMarkerForSearchResult, this._updateMarkerZIndexOffset);
      }

      this._markerIdToZIndexOffset = {};

      window.addEventListener("resize", this._onWindowResize.bind(this));
    }

    addMarker(id: string, latLng: L.LatLngExpression, options?: MarkerOptions): Marker {
      const marker = this._createMarker(id, L.latLng(latLng), options);
      if (marker) {
        this._showMarker(marker);
        this._markerIdToZIndexOffset[id] = options.zIndexOffset;
      }
      return marker;
    }

    removeMarker(markerOrId: string | Marker): Marker {
      const marker = this._getMarker(markerOrId);
      if (marker) {
        this._hideMarker(marker, true);
        delete this._markerIdToZIndexOffset[marker.id];
      }
      return marker;
    }

    removeAllMarkers(): void {
      Object.keys(this._markers).forEach(id => {
        this.removeMarker(id);
      });
    }

    showMarker(markerOrId: string | Marker): Marker {
      const marker = this._getMarker(markerOrId);
      if (marker) {
        this._showMarker(marker);
      }
      return marker;
    }

    hideMarker(markerOrId: string | Marker): Marker {
      const marker = this._getMarker(markerOrId);
      if (marker) {
        this._hideMarker(marker);
      }
      return marker;
    }

    selectMarker(markerOrId: string | Marker): Marker {
      const marker = this._getMarker(markerOrId);
      if (marker) {
        if (!this._isMarkerSelected(marker)) {
          this.deselectMarker();
          this._selectedMarkerId = marker.id;
          marker.setZIndexOffset(1000);
          this._updateMarkerIcon(marker);
        }
      }
      return marker;
    }

    deselectMarker(): void {
      const currentSelectedMarker = this._getMarker(this._selectedMarkerId);
      this._selectedMarkerId = null;
      if (currentSelectedMarker) {
        this._updateMarkerIcon(currentSelectedMarker);
        currentSelectedMarker.setZIndexOffset(this._markerIdToZIndexOffset[currentSelectedMarker.id]);
      }
    }

    moveMarker(markerOrId: string | Marker, location: L.LatLngExpression): Marker {
      const marker = this._getMarker(markerOrId);
      if (marker) {
        marker.setLatLng(location);
      }
      return marker;
    }

    updateMarker(markerOrId: string | Marker, options: MarkerOptions): Marker {
      // this function can be passed a subset of the options to be selectively added or modified
      // absence of options does not mean we should remove that option from the existing options
      const marker = this._getMarker(markerOrId);
      if (!marker) { return marker; }
      if (options === null || options === undefined) { return marker; }

      if ("lat" in options || "lng" in options) {
        const lat = options.lat === undefined ? marker.getLatLng().lat : options.lat;
        const lng = options.lng === undefined ? marker.getLatLng().lng : options.lng;
        marker.setLatLng(L.latLng(lat, lng));
      }

      if ("iconKey" in options) {
        this._updateMarkerIcon(marker, options.iconKey);
      }

      if (elevationKey in options) {
        marker.setElevation(options[elevationKey]);
      }

      if (elevationModeKey in options) {
        marker.setElevationMode(options[elevationModeKey]);
      }

      const changeIndoorMapOptions = mcIsIndoorKey in options ||
            mcIndoorIdKey in options ||
            mcFloorIndexKey in options;

      if (!changeIndoorMapOptions) { return marker; }

      const isIndoor = options[mcIsIndoorKey];

      if (isIndoor === false) {
        marker.setOutdoor();
        return marker;
      }

      const indoorMapId = options[mcIndoorIdKey] !== undefined ? options[mcIndoorIdKey] : marker.options[indoorMapIdKey];

      if (indoorMapId === undefined) { return marker; }

      // there is no legacy version of floorId, as it wasn't available when marker controller was originally written
      const indoorMapFloorId = marker.options[indoorMapFloorIdKey];

      // there _is_ a legacy version of floorIndex
      const indoorMapFloorIndex = options[mcFloorIndexKey] !== undefined ? options[mcFloorIndexKey] : marker.options[indoorMapFloorIndexKey];

      if(indoorMapFloorId !== undefined) {
        marker.setIndoorMapWithFloorId(indoorMapId, indoorMapFloorId);
      }
      else if (indoorMapFloorIndex !== undefined) {
        marker.setIndoorMapWithFloorIndex(indoorMapId, indoorMapFloorIndex);
      }

      return marker;
    }

    getAllMarkerIds(): string[] {
      return Object.keys(this._markers);
    }

    getMarker(id: string): Marker {
      return this._getMarker(id);
    }

    private _tags_in_ignore_list(tags: string[]): boolean {
      for(let i = 0; i < tags.length; i++) {
        const tag = tags[i];
        if(this._options.ignoreTags.includes(tag)) {
          return true;
        }
      }
      return false;
    }

    private _addMarkerForSearchResult(id: string, result: any, zIndexOffset: number): void {
      this.fire("beginsearchresultaddmarker", { result: result });

      if((result.data && result.data.tags && this._tags_in_ignore_list(result.data.tags.split(" ")))
           || (result.tags && this._tags_in_ignore_list(result.tags))) {
        return;
      }

      const latLng = result.location.latLng;
      const options = {} as Record<string, unknown>;
      if ("elevation" in result.location) {
        options.elevation = result.location.elevation;
      }
      if ("isIndoor" in result.location) {
        options.isIndoor = result.location.isIndoor;
      }
      if ("indoorId" in result.location) {
        options.indoorId = result.location.indoorId;
      }
      if ("floorIndex" in result.location) {
        options.floorIndex = result.location.floorIndex;
      }
      if ("iconKey" in result) {
        options.iconKey = result.iconKey;
      }

      if (this._options.poiViewsEnabled) {
        options.poiView = result;
      }

      options.zIndexOffset = zIndexOffset;

      const marker = this.addMarker(id, latLng, options);
      this.fire("searchresultaddmarker", { marker: marker, result: result });
    }

    private _updateMarkerZIndexOffset(id: string, zIndexOffset: number): void {
      const marker = this._getMarker(id);
      if (marker) {
        marker.setZIndexOffset(zIndexOffset);
      }
    }

    private _removeMarkerForSearchResult(id: string, sourceId: string): void {
      const marker = this.removeMarker(id);
      this.fire("searchresultremovemarker", { marker: marker, sourceId: sourceId });
    }

    private _getMarker(markerOrId: string | Marker): Marker | null {
      if (typeof markerOrId === "number" || typeof markerOrId === "string") {
        if (this._searchbarSubscriber) {
          const id = this._searchbarSubscriber.getLocalIdFromSourceId(markerOrId);
          return id !== null ? this._markers[id] : this._markers[markerOrId];
        }
        return markerOrId in this._markers ? this._markers[markerOrId] : null;
      }
      if (markerOrId !== null) {
        return this._markers[markerOrId.id] === markerOrId ? markerOrId : null;
      }
      return null;
    }

    // copies all marker controller options into a new options obj and converts to new format
    private _convertMarkerControllerToWrldJsMarkerOptions(legacyOptions: wrld.Marker.Options): wrld.Marker.Options & { iconKey?: string } {
      const options = JSON.parse(JSON.stringify(legacyOptions));

      const markerOptionIsOutdoors = options[mcIsIndoorKey] === false;

      // don't bother doing any kind of conversion or auto-setting for indoors when the marker is explicitly set to be outdoors.
      // Due to the way the old filters worked, there was an explicit flag "isIndoors" -- we must respect this, as setting
      // options.indoorMapId and indoorMapFloorId/indoorMapFloorIndex results in the marker being added to the map.
      if (!markerOptionIsOutdoors) {
        if (this._map.indoors.isIndoors()) {
          if (!(mcIndoorIdKey in options)) {
            options[mcIndoorIdKey] = this._map.indoors.getActiveIndoorMap().getIndoorMapId();
          }

          if (!(mcFloorIndexKey in options)) {
            options[indoorMapFloorIdKey] = this._map.indoors.getFloor().getFloorShortName();
          }
        }

        if (mcIndoorIdKey in options) {
          options[indoorMapIdKey] = options[mcIndoorIdKey];
        }

        if (mcFloorIndexKey in options) {
          options[indoorMapFloorIndexKey] = options[mcFloorIndexKey];
        }
      }

      // we've got the new format now; delete the old ones
      delete options[mcIsIndoorKey];
      delete options[mcIndoorIdKey];
      delete options[mcFloorIndexKey];

      return options;
    }

    private _createMarker(id: string, latLng: L.LatLngExpression, options?: wrld.Marker.Options): Marker | null {
      if (id in this._markers) { return null; }

      const legacyOptions = options || {};
      const markerOptions = this._convertMarkerControllerToWrldJsMarkerOptions(legacyOptions);

      const marker = L.marker(latLng, markerOptions) as Marker;

      if ("iconKey" in markerOptions) {
        marker.iconKey = markerOptions.iconKey;
      }

      marker.visible = false;
      marker.isAnimating = false;
      marker.oneTimeAnimationEndCallback = null;
      marker.dragEndCallback = null;
      marker.anchorAnimatingCallback = null;
      marker.id = id;
      marker.isBeingDragged = false;
      marker.isAnchorAnimating = false;

      marker.on("add", () => {
        this._animateMarker(marker, "appear");
        marker.visible = true;
      });

      marker.on("click", () => {
        if(this._options.skipPoiCard) {
          this.openPoiView(marker);
        }
      });

      marker.on("dragstart", () => {
        marker.isBeingDragged = true;
      });

      marker.on("dragend", () => {
        marker.isBeingDragged = false;
        if(marker.dragEndCallback) {
          marker.dragEndCallback();
          marker.dragEndCallback = null;
        }
      });

      if (this._options.poiViewsEnabled) {
        this._createPoiViewForMarker(marker, markerOptions);
      }

      this._markers[id] = marker;
      return marker;
    }

    private _isMarkerSelected(marker: Marker): boolean {
      return marker.id === this._selectedMarkerId;
    }

    private _showMarker(marker: Marker): void {
      if (marker.visible) { return; }

      this._updateMarkerIcon(marker, undefined, false);
      marker.addTo(this._map);
    }

    private _hideMarker(marker: Marker, removeMarker = false): void {
      if (!marker.visible && !removeMarker) { return; }

      if (this._isMarkerOnMap(marker) && marker.iconKey !== undefined && marker.visible) {
        this._updateMarkerIcon(marker, undefined, false);
        this._animateMarker(marker, "disappear", () => {
          this._removeMarkerFromMap(marker);
        });
      }
      else {
        this._removeMarkerFromMap(marker);
      }

      if (removeMarker) {
        this._addMarkerForDeletion(marker);
      }
    }

    private _removeMarkerFromMap(marker: Marker): void {
      this._map.removeLayer(marker);
      marker.visible = false;
    }

    private _addMarkerForDeletion(marker: Marker): void {
      const id = marker.id;
      if (this._searchbarSubscriber !== null) {
        this._searchbarSubscriber.removeMarker(id);
      }
      this._markersPendingDelete[id] = marker;
      delete this._markers[id];
    }

    private _onUpdate(): void {
      Object.keys(this._markersPendingDelete).forEach((id) => {
        const marker = this._markersPendingDelete[id];
        if (!this._isMarkerOnMap(marker)) {
          this._map.removeLayer(marker);
          delete this._markersPendingDelete[id];
        }
      });
    }

    private _updateAnchors(): void {
      if (this._hasAnchors === this._shouldHaveAnchors()) { return; }
      this._hasAnchors = this._shouldHaveAnchors();

      for (const id in this._markers) {
        const marker = this._markers[id];
        if (this._isMarkerOnMap(marker)) {
          if (this._hasAnchors && !$(marker.getElement()).hasClass("has-anchor")) {
            marker.isAnchorAnimating = true;
            $(marker.getElement()).addClass("has-anchor");
            $(marker.getElement()).bind("transitionend", () => {
              marker.isAnchorAnimating = false;
              if (marker.anchorAnimatingCallback) {
                marker.anchorAnimatingCallback();
                marker.anchorAnimatingCallback = null;
              }
              this._updateMarkerIcon(marker);
            });
          }
          else if ($(marker.getElement()).hasClass("has-anchor")) {
            marker.isAnchorAnimating = true;
            $(marker.getElement()).removeClass("has-anchor");
            $(marker.getElement()).bind("transitionend", () => {
              marker.isAnchorAnimating = false;
              if (marker.anchorAnimatingCallback) {
                marker.anchorAnimatingCallback();
                marker.anchorAnimatingCallback = null;
              }
              this._updateMarkerIcon(marker);
            });
          }
        }
      }

      this._updateMarkerPopupOffset();
    }

    private _shouldHaveAnchors(): boolean {
      return this._map.getCameraPitchDegrees() < 60;
    }

    private _updateMarkerIcon(marker: Marker, iconKey?: string, waitForAnimationToEnd = true): void {
      if (marker.iconKey === undefined) { return; }
      if (iconKey !== undefined) {
        marker.iconKey = iconKey;
      }
      const markerIcon = createMarkerIcon(this._isMarkerSelected(marker), this._hasAnchors, marker.iconKey);

      if (waitForAnimationToEnd && marker.isAnimating) {
        marker.oneTimeAnimationEndCallback = () => {
          this._setIcon(marker, markerIcon);
        };
      }
      else {
        this._setIcon(marker, markerIcon);
      }
    }

    private _setIcon(marker: Marker, markerIcon: L.DivIcon): void {
      if(!marker.isAnchorAnimating) {
        marker.dragEndCallback = null;
        marker.anchorAnimatingCallback = null;
        if(!marker.isBeingDragged) {
          marker.setIcon(markerIcon);
        }
        else if(marker.isBeingDragged){
          marker.dragEndCallback = () => {
            marker.setIcon(markerIcon);
          };
        }
      }
    }

    private _animateMarker(marker: Marker, animation: Animation, onAnimationEndCallback?: () => void): void {
      if (!this._isMarkerOnMap(marker)) { return; }

      const animatingNode = marker.getElement().childNodes[0];
      this._animationKeys.forEach(animation => {
        $(animatingNode).removeClass(animation);
      });
      $(animatingNode).addClass(animation);
      marker.isAnimating = true;
      $(animatingNode).bind("animationend", () => {
        marker.isAnimating = false;
        if (marker.oneTimeAnimationEndCallback) {
          marker.oneTimeAnimationEndCallback();
          marker.oneTimeAnimationEndCallback = null;
        }
        if (onAnimationEndCallback) {
          onAnimationEndCallback();
        }
      });
    }

    private _isMarkerOnMap(marker: Marker): boolean {
      return marker.getElement() !== null && marker.getElement() !== undefined;
    }

    openPoiCard(markerOrId: string | Marker): Marker {
      if(this._options.skipPoiCard) { return null; }
      const marker = this._getMarker(markerOrId);
      if (!marker) { return null; }
      if (this._options.poiViewsEnabled === true) {
        this._openPopup(marker, marker._poiCardContainer);
      }
      return marker;
    }

    openPoiView(markerOrId: string | Marker): Marker {
      const marker = this._getMarker(markerOrId);
      if (!marker) { return null; }
      if (this._options.poiViewsEnabled === true) {
        this._openPopup(marker, marker._poiViewContainer);
      }
      return marker;
    }

    closePoiCard(markerOrId: string | Marker): Marker {
      const marker = this._getMarker(markerOrId);
      if (!marker) { return null; }
      if (this._options.poiViewsEnabled === true) {
        this._closePopupIfBound(marker, marker._poiCardContainer);
      }
      return marker;
    }

    closePoiView(markerOrId: string | Marker): Marker {
      const marker = this._getMarker(markerOrId);
      if (!marker) { return null; }
      if (this._options.poiViewsEnabled === true) {
        this._closePopupIfBound(marker, marker._poiViewContainer);
      }
      return marker;
    }

    private _createPoiViewForMarker(marker: Marker, markerOptions: MarkerOptions) {
      this.fire("willopenpoiview", {poiData: markerOptions.poiView});
      const validPoiViewData = ensureValidPoiViewData(marker.iconKey, markerOptions.poiView);
      if(!this._options.skipPoiCard) {
        marker._poiCardContainer = PoiCardContainer(validPoiViewData);
        marker._poiCardContainer.addEventListener("click", () => {
          const selectedText = getSelection().toString();
          if (selectedText) { return; }
          this.openPoiView(marker);
        });
      }

      const options: PoiViewOptions = pick(this._options, ["customViewScrollingEnabled", "customViewDefaultHeight", "customViewDataDeliveryMethod"]);
      marker._poiViewContainer = PoiViewContainer(this._map, validPoiViewData, options, () => { this._map.closePopup(); });

      marker.on("popupopen", (e: PopupEvent) => {
        this._onMarkerPopupOpen(e.popup);
      });
      marker.on("popupclose", (e: PopupEvent) => {
        this._onMarkerPopupClose(e.popup);
        // This ensures the poi card is always the bound popup as when closing the poi view the poi view will still be bound.
        if (!this._options.skipPoiCard && e.popup.getContent() !== marker._poiCardContainer) {
          this._bindAndOffsetPopup(marker, marker._poiCardContainer);
        }
        else if(this._options.skipPoiCard) {
          marker.unbindPopup();
        }
      });

      if(!this._options.skipPoiCard) {
        this._bindAndOffsetPopup(marker, marker._poiCardContainer);
      }

    }

    private _openPopup(marker: Marker, popupContent: PopupContent): void {
      if (!marker.getPopup() || marker.getPopup().getContent() !== popupContent) {
        this._bindAndOffsetPopup(marker, popupContent);
      }
      marker.openPopup();
    }

    private _calculatePopupOffset(popupContent: PopupContent): [number, number] {
      const dimensions = measurePopupOnMap(this._map, popupContent, "wrld-marker-popup");
      const halfMarkerWidth = 22;
      return [dimensions.width/2 + halfMarkerWidth, dimensions.height/2];
    }

    private _bindAndOffsetPopup(marker: Marker, popupContent: PopupContent): void {
      marker.bindPopup(popupContent, {
        offset: this._calculatePopupOffset(popupContent),
        className: "wrld-marker-popup",
        closeButton: false
      });
    }

    private _closePopupIfBound(marker: Marker, popupContent: PopupContent): void {
      if (!marker.isPopupOpen()) { return; }
      if (marker.getPopup().getContent() === popupContent) {
        marker.closePopup();
      }
    }

    private _onMarkerPopupOpen(popup?: wrld.Popup | null): void {
      if (!popup) { return; }
      // @ts-ignore legacy access to private members here
      const popupContainer = popup._container;
      popupContainer.id = "offset-wrld-marker-popup";
      $(popupContainer).removeClass("animate-offset-for-anchor");

      if (this._hasAnchors) {
        $(popupContainer).addClass("offset-for-anchor");
      }
      else {
        $(popupContainer).removeClass("offset-for-anchor");
      }
    }

    private _onMarkerPopupClose(popup: wrld.Popup): void {
      if (!popup) { return; }
      // @ts-ignore legacy access to private members here
      const popupContainer = popup._container;
      popupContainer.id = "";
    }

    private _updateMarkerPopupOffset(): void {
      const popupContainer = document.getElementById("offset-wrld-marker-popup");
      if (!popupContainer) { return; }
      $(popupContainer).addClass("animate-offset-for-anchor");

      if (this._hasAnchors) {
        $(popupContainer).addClass("offset-for-anchor");
        $(popupContainer).bind("transitionend", () => {
          $(popupContainer).removeClass("animate-offset-for-anchor");
        });
      }
      else {
        $(popupContainer).removeClass("offset-for-anchor");
        $(popupContainer).bind("transitionend", () => {
          $(popupContainer).removeClass("animate-offset-for-anchor");
        });
      }
    }

    private _onWindowResize(): void {
      for (const id in this._markers) {
        const marker = this._markers[id];
        const popup = marker.getPopup();
        if (popup) {
          resizePoiViewContainerToFit(this._map, marker._poiViewContainer);
          if (popup.getContent() === marker._poiViewContainer) {
            popup.options.offset = L.point(this._calculatePopupOffset(marker._poiViewContainer));
          }
        }
      }
    }
}

const wrldMarkerController = (map: wrld.Map): WrldMarkerController => {
  return new WrldMarkerController(map);
};

export {
  WrldMarkerController,
  wrldMarkerController,
  WrldMarkerController as EegeoMarkerController,
  wrldMarkerController as eegeoMarkerController
};
