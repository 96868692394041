import tagService from "../../../../common/template/services/TagService";
import {
  createDivWithClassName,
  createButtonWithClassName,
  addDivWithClassName,
} from "../../helpers/DomHelpers";
import { Poi } from "../../types/Poi";

export const getPoiViewHeader = (
  poiData: Poi,
  hidePoiCallback?: () => void
): HTMLDivElement => {
  const iconKey = poiData.iconKey;
  const iconUrl = tagService.getIconUrlForKey(iconKey);
  const headerElement = createDivWithClassName("poi-view-header");
  const iconElement = addDivWithClassName("tag-icon", headerElement);
  iconElement.style.backgroundImage = "url(" + iconUrl + ")";
  const buttonElement = createButtonWithClassName("close-button");
  buttonElement.onclick = hidePoiCallback;
  headerElement.appendChild(buttonElement);
  addDivWithClassName("title-text", headerElement, poiData.title);
  return headerElement;
};
