import { Poi } from "./types/Poi";
import { Searchbar } from "./types/Searchbar";

type AddMarkerForSearchResult = (id: string, poi: Poi, zIndexOffset: number) => void;
type RemoveMarkerForSearchResult = (id: string, sourceId: string) => void;
type UpdateMarkerZIndexOffset = (id: string, zIndexOffset: number) => void;

export default class SearchbarSubscriber
{
  private _searchbar: Searchbar;
  private _addMarkerForSearchResult: AddMarkerForSearchResult;
  private _removeMarkerForSearchResult: RemoveMarkerForSearchResult;
  private _updateMarkerZIndexOffset: UpdateMarkerZIndexOffset;
  private _sourceToLocalIds: Record<string, string>;
  private _localToSourceIds: Record<string, string>;

  constructor(searchbar: Searchbar, addMarkerForSearchResult: AddMarkerForSearchResult, removeMarkerForSearchResult: RemoveMarkerForSearchResult, updateMarkerZIndexOffset: UpdateMarkerZIndexOffset) {
    this._searchbar = searchbar;
    this._addMarkerForSearchResult = addMarkerForSearchResult;
    this._removeMarkerForSearchResult = removeMarkerForSearchResult;
    this._updateMarkerZIndexOffset = updateMarkerZIndexOffset;

    this._sourceToLocalIds = {};
    this._localToSourceIds = {};

    this._searchbar.on("searchresultsupdate", (event) => this._addMarkersFromJSON(event.results));
    this._searchbar.on("searchresultsclear", () => this._clearMarkers());
  }

  removeMarker(id: string): void {
    if (id in this._localToSourceIds) {
      const sourceId = this._localToSourceIds[id];
      delete this._sourceToLocalIds[sourceId];
      delete this._localToSourceIds[id];
    }
  }

  getLocalIdFromSourceId(sourceId: string): string | null {
    return sourceId in this._sourceToLocalIds ? this._sourceToLocalIds[sourceId] : null;
  }

  private _addMarkersFromJSON(json: Poi[] | Record<string, Poi>): void {
    const markersToRemove = Object.assign({}, this._localToSourceIds);

    let zIndexOffset = 0;
    if (Array.isArray(json)) {
      json.forEach((poi) => {
        const id = poi["id"];
        this._addMarkerForSearchResult(id, poi, zIndexOffset--);
      });
    } else {
      for (const id in json) {
        const sourceId = json[id]["sourceId"];
        if (sourceId in this._sourceToLocalIds) {
          // We already have this search result, keep it and don't duplicate it.
          const localId = this._sourceToLocalIds[sourceId];
          delete markersToRemove[localId];
          this._updateMarkerZIndexOffset(id, zIndexOffset--);
          continue;
        }

        this._sourceToLocalIds[sourceId] = id;
        this._localToSourceIds[id] = sourceId;
        this._addMarkerForSearchResult(id, json[id], zIndexOffset--);
      }
    }

    for (const id in markersToRemove) {
      const sourceId = markersToRemove[id];
      this._removeMarkerForSearchResult(id, sourceId);
    }
  }

  private _clearMarkers(): void {
    for (const id in this._localToSourceIds) {
      const sourceId = this._localToSourceIds[id];
      this._removeMarkerForSearchResult(id, sourceId);
    }
  }
}
