/** Need the one below to support legacy code that accesses private properties */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import type wrld from "wrld.js";

export type Dimensions = { width: number; height: number; };

export const measurePopupOnMap = (map: wrld.Map, popupContent: ((source: L.Layer) => L.Content) | L.Content, className: string): Dimensions => {
  const pixelBounds = map.getPixelBounds();
  const offscreenPopup = L.popup({className: className, offset: pixelBounds.getSize()})
    .setLatLng(map.getCenter())
    .setContent(popupContent);
  map.addLayer(offscreenPopup);
  const dimensions = {
    // @ts-ignore historical access to private property
    width: offscreenPopup._container.offsetWidth as number,
    // @ts-ignore historical access to private property
    height: offscreenPopup._container.offsetHeight as number
  };
  map.removeLayer(offscreenPopup);
  return dimensions;
};
