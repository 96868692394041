import { Poi } from "../types/Poi";
import { SearchResult } from "../../../common/@types/SearchResult";

export const ensureValidPoiViewData = (iconKey: string, poiViewData: Poi | SearchResult): Poi => {
  // TODO: this function does really dodgy things – it should be refactored correctly create a new Poi from SearchResult
  const validPoiViewData = Object.assign({}, poiViewData) as Poi;
  validPoiViewData.title = validPoiViewData.title || "";
  validPoiViewData.iconKey = iconKey;
  validPoiViewData.data = validPoiViewData.data || {};
  validPoiViewData.data.user_data = validPoiViewData.data.user_data || Object.assign({}, poiViewData);

  if (validPoiViewData.data.user_data.imageUrl) {
    validPoiViewData.data.user_data.image_url = validPoiViewData.data.user_data.imageUrl;
  }
  if (validPoiViewData.data.user_data.customView) {
    validPoiViewData.data.user_data.custom_view = validPoiViewData.data.user_data.customView;
  }
  if (validPoiViewData.data.user_data.customViewHeight) {
    validPoiViewData.data.user_data.custom_view_height = validPoiViewData.data.user_data.customViewHeight;
  }

  return validPoiViewData;
};
