import tagService from "./../../../common/template/services/TagService";

export const createMarkerIcon = (isSelected: boolean, hasAnchor: boolean, iconKey: string | null): L.DivIcon => {
  const hasIconKey = iconKey !== null;
  const className = "eegeo-marker" + (isSelected ? " selected" : "") + (hasAnchor ? " has-anchor" : "");
  const bodyClassName = "eegeo-marker-body" + (hasIconKey ? " has-icon" : "");
  const iconUrl = tagService.getIconUrlForKey(iconKey);
  const pinStyle = hasIconKey ? `background-image: url(${iconUrl})` : "";
  const html =
  `<div class="eegeo-marker-container">
    <div class="${bodyClassName}"></div>
    <div class="eegeo-marker-anchor"></div>
    <div class="eegeo-marker-pin-icon" style="${pinStyle}"></div>
  </div>`;

  return L.divIcon({
    className: className,
    iconSize: null,
    html: html
  });
};
