import { createDivWithClassName, addDivWithClassName, createElementWithClassName, ensureProtocolInUrl, formatUrl } from "../../helpers/DomHelpers";
import { PoiData } from "../../types/Poi";


const addLinkElement = (className: string, parent: HTMLElement, href: string, target?: string) => {
  const linkElt = createElementWithClassName("a", className) as HTMLAnchorElement;
  linkElt.href = ensureProtocolInUrl(href);
  linkElt.target = target;
  parent.appendChild(linkElt);
  return linkElt;
};

export const getPoiViewDetails = (poiData: PoiData): HTMLDivElement => {
  const detailContainer = createDivWithClassName("poi-view-details");
  if (poiData.user_data.address) {
    addDivWithClassName("address-text", detailContainer, poiData.user_data.address);
  }
  if (poiData.user_data.phone) {
    addDivWithClassName("phone-text", detailContainer, poiData.user_data.phone);
  }
  if (poiData.user_data.web){
    const linkElt = addLinkElement("web-text", detailContainer, ensureProtocolInUrl(poiData.user_data.web), "_blank");
    const divText = document.createTextNode(poiData.user_data.web);
    linkElt.appendChild(divText);
  }
  if (poiData.user_data.email || poiData.user_data.facebook || poiData.user_data.twitter) {
    const linkDiv = addDivWithClassName("link-icons", detailContainer);
    if (poiData.user_data.email) {
      addLinkElement("email-icon", linkDiv, "mailto:" + poiData.user_data.email);
    }
    if (poiData.user_data.facebook) {
      addLinkElement("facebook-icon", linkDiv, formatUrl(poiData.user_data.facebook, "facebook.com", "www.facebook.com/", true), "_blank");
    }
    if (poiData.user_data.twitter) {
      addLinkElement("twitter-icon", linkDiv, formatUrl(poiData.user_data.twitter, "twitter.com", "twitter.com/", true), "_blank");
    }
    detailContainer.appendChild(linkDiv);
  }
  return detailContainer;
};
