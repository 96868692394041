
const EVENT_BUTTON = {
  LEFT: 0,
  MIDDLE: 1,
  RIGHT: 2,
  BACK: 3,
  FORWARD: 4
};

const BITWISE_EVENT_BUTTONS = {
  NONE: 0,
  LEFT: 1,
  RIGHT: 2,
  MIDDLE: 4,
  BACK: 8,
  FORWARD: 16
};

// We only care about middle and right buttons for now. MPLY-11317
const buttonsToCheck = ["MIDDLE", "RIGHT"];

const createMouseEvent = (eventType: string, button: number, currentButtonsState: number) => {
  return new MouseEvent(eventType, {
    view: window,
    bubbles: true,
    button: button,
    buttons: currentButtonsState
  });
};

export const simulateMouseUpEventsInsideIframe = (iframe: HTMLIFrameElement): void => {
  let buttonsStateOnMouseOver = 0;

  iframe.addEventListener("mouseover", (event) => {
    buttonsStateOnMouseOver = event.buttons;
  });

  iframe.addEventListener("mouseout", (event) => {
    const buttonsStateOnMouseOut = event.buttons;
    const buttonsStateChanged = buttonsStateOnMouseOver ^ buttonsStateOnMouseOut;

    buttonsToCheck.forEach(buttonName => {
      const bitwiseButtonValue = BITWISE_EVENT_BUTTONS[buttonName];
      const hasButtonStateChanged = buttonsStateChanged & bitwiseButtonValue;

      if (hasButtonStateChanged) {
        const buttonWasReleased = (buttonsStateOnMouseOut & bitwiseButtonValue) === 0;
        if (buttonWasReleased) {
          const simulatedEvent = createMouseEvent("mouseup", EVENT_BUTTON[buttonName], buttonsStateOnMouseOut);
          window.dispatchEvent(simulatedEvent);
        }
      }
    });
  });
};
