import { createDivWithClassName, addDivWithClassName } from "../../helpers/DomHelpers";
import { Poi } from "../../types/Poi";

export const getPoiViewDescription = (poi: Poi): HTMLDivElement | null => {
  if (poi.subtitle || poi.data.user_data.description) {
    const descContainer = createDivWithClassName("poi-view-description-container");
    if (poi.subtitle) {
      addDivWithClassName("subtitle-text", descContainer, poi.subtitle);
    }
    if (poi.data.user_data.description) {
      addDivWithClassName("description-text", descContainer, poi.data.user_data.description);
    }
    return descContainer;
  }
  return null;
};
