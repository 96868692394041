import { createDivWithClassName, addDivWithClassName } from "../../helpers/DomHelpers";

export const getPoiViewImage = (url: string, isYelp: boolean): HTMLDivElement => {
  const imageUrl = (url && isYelp) ? url.replace(/\/ms.jpg$/, "/348s.jpg") : url;
  const imageContainer = createDivWithClassName("poi-view-image-container");
  const imageDiv = addDivWithClassName("poi-view-image", imageContainer);
  if (imageUrl) {
    imageDiv.style.backgroundImage = "url(" + imageUrl + ")";
  }
  return imageContainer;
};
